import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const VideoContainer = styled.section`
	video {
		width: 100%;
	}
`;

const Video = ({ video, autoPlay, loop, isPlaying }) => {
	const videoRef = useRef();
	useEffect(() => {
		isPlaying ? videoRef.current.play() : videoRef.current.pause();
	}, [isPlaying]);

	const url = video.file.url;
	return (
		<VideoContainer>
			<video muted autoPlay={autoPlay} loop={loop} ref={videoRef}>
				<source src={url} type='video/mp4' />
			</video>
		</VideoContainer>
	);
};

export default Video;
