import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Layout from '../components/layout';
import Video from '../components/video';
import PageHeading from '../components/page-heading';
import Tag from '../components/tag';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper';
import 'swiper/css';

const Tags = styled.ul`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`;

const Grid = styled.section`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 24px;
	column-gap: 64px;
	@media screen and (min-width: 1024px) {
		grid-template-columns: 1fr 2fr;
	}
`;

const Body = styled.article`
	p:not(.small) {
		margin-bottom: 24px;
	}
`;

const ThumbsSwiper = styled(Swiper)`
	margin-top: 20px;
	.swiper-slide {
		@media screen and (min-width: 768px) {
			border: 4px solid transparent;
		}
		transition: opacity 0.15s;
		opacity 0.6;
		cursor:pointer;
		&.swiper-slide-thumb-active {
			opacity 1;
				}
		&:not(.swiper-slide-thumb-active):hover {
			opacity 1;
		}
	}
`;

const GallerySwiper = styled(Swiper)``;

const Swipers = styled.section`
	margin-bottom: 60px;
`;

const Slide = ({ item, autoPlay = false, controls = false, loop = false, isPlaying }) => {
	if (item.mimeType === 'image/jpeg' || item.mimeType === 'image/png')
		return <GatsbyImage image={item.gatsbyImageData} alt={item.description} width={1560} />;
	if (item.mimeType === 'video/mp4')
		return <Video video={item} autoPlay={autoPlay} loop={loop} isPlaying={isPlaying} />;
};

const ProjectDetail = ({ data }) => {
	const project = data.contentfulProject;

	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	return (
		<Layout>
			<PageHeading
				text={project.title}
				url={project.linkToWebsite}
				button
				date={project.date}
				linkText={project.linkText}
				inDevelopment={project.inDevelopment}
			/>
			{project.media && (
				<Swipers>
					<GallerySwiper modules={[Thumbs]} thumbs={{ swiper: thumbsSwiper }} slidesPerView={1}>
						{project.media.map((item, index) => {
							return (
								<SwiperSlide key={index}>
									{({ isActive }) => (
										<Slide item={item} autoPlay={true} loop={true} controls={true} isPlaying={isActive} />
									)}
								</SwiperSlide>
							);
						})}
					</GallerySwiper>
					{project.media.length > 1 && (
						<ThumbsSwiper
							centerInsufficientSlides={true}
							spaceBetween={8}
							breakpoints={{
								// when window width is >= 480px
								480: {
									spaceBetween: 16,
									slidesPerView: 4.5
								},
								// when window width is >= 640px
								640: {
									slidesPerView: 6.5
								}
							}}
							slidesPerView={3.5}
							modules={[Thumbs]}
							watchSlidesProgress
							onSwiper={setThumbsSwiper}>
							{project.media.map((item, index) => {
								return (
									<SwiperSlide key={index}>
										<Slide item={item} />
									</SwiperSlide>
								);
							})}
						</ThumbsSwiper>
					)}
				</Swipers>
			)}
			<Grid>
				<div>
					<h4>{project.blurb}</h4>
				</div>
				{project.body && (
					<div>
						<Body
							dangerouslySetInnerHTML={{
								__html: project.body?.childMarkdownRemark.html
							}}
						/>
						{project.tags && project.tags.length > 0 && (
							<>
								<Tags>
									{project.tags.map(tag => (
										<Tag key={tag}>{tag}</Tag>
									))}
								</Tags>
							</>
						)}
					</div>
				)}
			</Grid>
		</Layout>
	);
};

export default ProjectDetail;

export const pageQuery = graphql`
	query projectQuery($id: String!) {
		contentfulProject(id: { eq: $id }) {
			id
			title
			slug
			date(formatString: "YYYY")
			createdAt(formatString: "MMMM YYYY")
			body {
				childMarkdownRemark {
					html
				}
			}
			logo {
				gatsbyImageData(width: 64)
			}
			media {
				gatsbyImageData(width: 1560)
				mimeType
				description
				file {
					url
				}
				id
			}
			categories
			tags
			linkToWebsite
			linkText
			blurb
			inDevelopment
		}
	}
`;
