import React from 'react';
import styled from 'styled-components';

const TagLi = styled.li`
	font-size: 14px;
	padding: 6px 10px;
	background-color: var(--background-knocked);
	border-radius: 3px;
`;

const Tag = ({ children }) => {
	return <TagLi>{children}</TagLi>;
};

export default Tag;
